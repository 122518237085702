<template>
    <v-app
        :class="{ pageRoot: $hasAuth(), activeNavbar: navDrawer && $hasAuth() }"
    >
        <MainNavigation
            v-if="$hasAuth()"
            v-model="navDrawer"
            />
        <v-app-bar
            app
            v-if="!['Login'].includes($route.name)"
            dark
            color="primary"
            :extension-height-old="portalAppBarNavigationExtension ? 72 : 0"
            :extension-height="portalAppBarNavigationExtension ? 53 : 0"
            class="v-app-bar--lea"
            :elevate-on-scroll="$route.meta && $route.meta.appBarElevateOnScroll"
            style="margin-left:56px"
        >
            <template v-slot:default>
                <template v-if="!$hasAuth()" >
                    <v-btn depressed icon small :to="'/'" class="ml-6" color="white">
                        <v-icon v-text="'home'" />
                    </v-btn>
                </template>
                <template v-else>
                    <v-toolbar-title style="max-width:50%">
                        <portal-target name="appBarNavigationTitle" @change="portalAppBarNavigationTitle = $event" multiple></portal-target>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <portal-target name="appBarNavigationSearch" @change="portalAppBarNavigationSearch = $event"></portal-target>
                    <portal-target name="appBarNavigationAction" @change="portalAppBarNavigationAction = $event"></portal-target>
                    <CurrentUserAvatarMenu />
                </template>
                <template>
                    <HiddenIFrame v-if="$ssoMode" />
                </template>
            </template>
            <template v-slot:extension>
                <portal-target name="appBarNavigationExtension" @change="portalAppBarNavigationExtension = $event"></portal-target>
            </template>
        </v-app-bar>
        <v-main
            style="margin-left:56px;">
            <keep-alive include="Home">
                <router-view />
            </keep-alive>
        </v-main>
        <v-footer color="application" padless class="mt-0">
            <v-row justify="center" no-gutters>
                <v-col cols="12" class="pt-0">
                    <v-divider />
                </v-col>
                <v-col
                    class="grey--text text--lighten-1 py-1 text-center"
                    cols="12"
                    sm="4"
                    lg="3"
                >
                    &copy; {{ year_copyright }} —
                    <strong>BEN digital services GmbH</strong>
                </v-col>
                <v-col
                    v-for="(link, index) in footerLinks"
                    :key="index"
                    class="py-1 text-center"
                    cols="6"
                    sm="3"
                    md="2"
                    lg="1"
                >
                    <router-link
                        flat
                        :ripple="false"
                        :to="link.to"
                        class="grey--text text--lighten-1"
                    >
                        {{ link.title }}
                    </router-link>
                </v-col>
            </v-row>
        </v-footer>
        <v-wait for="pageLoader">
            <template v-slot:waiting>
                <v-dialog
                    :hide-overlay="false"
                    :value="true"
                    persistent
                    content-class="pageLoader"
                >
                    <v-card color="primary mx-auto" flat width="100">
                        <v-card-text class="text-center pa-3">
                            <v-progress-circular
                                indeterminate
                                color="white"
                            ></v-progress-circular>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>
        </v-wait>
        <BaseResponseDialog
            v-model="serverResponseDialog"
            :error-dialog="errorDialog"
            :headline="serverResponseDialogHeadline"
            :message="serverResponseDialogText"
            :error-code-message="serverResponseDialogStatusCodeMessage"
            :support-id="serverResponseDialogSupportId"
        />
        <v-dialog v-model="apiVersionConflictDialog" max-width="340" persistent>
            <v-card>
                <v-card-title
                    class="text-h5 warning--text"
                    v-text="$t('apiVersionConflictDialog.title')"
                />
                <v-card-text>
                    {{ $t('apiVersionConflictDialog.message') }}
                </v-card-text>
                <v-card-actions>
                    <v-btn color="success" text @click="reloadPage()">
                        {{ $t('apiVersionConflictDialog.refreshLinkText') }}
                    </v-btn>
                    <v-btn text @click="ignoreApiVersionConflictDialog()">
                        {{ $t('ignore') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-tooltip top color="primary">
            <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    id="fabPageTopButton"
                    v-scroll-to="'#app'"
                    color="primary"
                    small
                    fixed
                    bottom
                    right
                    fab
                >
                    <v-icon>arrow_drop_up</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('toTopOfPage') }}</span>
        </v-tooltip>
    </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'lodash';
import { eventBus } from '@/event-bus.js';
import mercureMixin from '@/mixins/mercureMixin';
import activeAppsMixin from '@/mixins/activeAppsMixin';
import MainNavigation from '../components/layout/mainNavigation';
import CurrentUserAvatarMenu from '../components/layout/CurrentUserAvatarMenu.vue';
import HiddenIFrame from '../components/layout/HiddenIFrame.vue';

export default {
    name: 'App',
    components: {
        MainNavigation,
        CurrentUserAvatarMenu,
        HiddenIFrame
    },
    mixins: [mercureMixin, activeAppsMixin],
    data() {
        const d = new Date();
        return {
            portalAppBarNavigationTitle: false,
            portalAppBarNavigationSearch: false,
            portalAppBarNavigationAction: false,
            portalAppBarNavigationExtension: false,
            serverResponseDialog: false,
            apiVersionConflictDialog: false,
            errorDialog: true,
            serverResponseDialogHeadline: null,
            serverResponseDialogText: null,
            serverResponseDialogStatusCodeMessage: null,
            serverResponseDialogSupportId: null,
            navDrawer: true,
            year_copyright: d.getFullYear(),
            handleDebouncedScroll: null,
            footerLinks: [
                {
                    title: this.$i18n.t('footer.imprint'),
                    to: { name: 'Imprint' },
                },
                {
                    title: this.$i18n.t('footer.privacy'),
                    to: { name: 'Privacy' },
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            getEventDataByKey: 'eventStore/getDataByKey',
            getMercureActivityChangedNotifications: 'mercure/getMercureActivityChangedNotifications',
        })
    },
    methods: {
        ...mapActions({
            fetchApps: 'apps/fetchApps',
            disableApiVersionConflictDialog: 'eventStore/disableApiVersionConflictDialog',
            invalidateKpi: 'dashboard/invalidateKpi'
        }),
        reloadPage() {
            this.$router.go({
                path: this.$router.path,
                query: {
                    t: +new Date(),
                },
            });
        },
        ignoreApiVersionConflictDialog() {
            this.disableApiVersionConflictDialog();
            this.apiVersionConflictDialog = false;
        },
        setBaseResponseDialog(statusCode, hasError, supportId) {
            this.$wait.end('pageLoader');
            this.serverResponseDialog = true;
            this.errorDialog = hasError;
            this.serverResponseDialogHeadline = this.$t('error');
            this.serverResponseDialogText = this.$t('genericErrorMessage');
            this.serverResponseDialogStatusCodeMessage = this.$t(`statusCodeMessages.${statusCode}`);
            this.serverResponseDialogSupportId = supportId;
        },
        displayApiVersionConflictDialog() {
            if (!this.getEventDataByKey('disableApiVersionConflictDialog')) {
                this.apiVersionConflictDialog = true;
            }
        },
        handleScroll() {
            this.$windowScrollState.setValue(window.scrollY);
        }
    },
    created() {
        this.handleDebouncedScroll = debounce(this.handleScroll, 100);
        window.addEventListener('scroll', this.handleDebouncedScroll);
        eventBus.$on(
            'setBaseResponseDialog',
            (statusCode, hasError, supportId) =>
                this.setBaseResponseDialog(statusCode, hasError, supportId)
        );
        eventBus.$on('displayApiVersionConflictDialog', () =>
            this.displayApiVersionConflictDialog()
        );
    },
    mounted() {
        if (this.$hasAuth()) {
            this.fetchApps().then(() => {
                this.initMercureSubscriptions(); // mixin
            });
        }
    },
    beforeDestroy() {

        window.removeEventListener('scroll', this.handleDebouncedScroll);
        eventBus.$off(
            'setBaseResponseDialog',
            (statusCode, hasError, supportId) =>
                this.setBaseResponseDialog(statusCode, hasError, supportId)
        );
        eventBus.$off('displayApiVersionConflictDialog', () =>
            this.displayApiVersionConflictDialog()
        );
        this.apiVersionConflictDialog = false;
        this.serverResponseDialog = false;
        this.errorDialog = false;
        this.serverResponseDialogHeadline = null;
        this.serverResponseDialogText = null;
        this.serverResponseDialogStatusCodeMessage = null;
        this.serverResponseDialogSupportId = null;
    },
    watch: {
        getMercureActivityChangedNotifications: {
            handler() {
                this.invalidateKpi();
            },
            deep: false
        },
    }
};
</script>
