<template>
    <div>
        <IFrame 
            v-if="showHiddenIFrame"
            @close="hideIFrame()"
            name="hiddenIFrame" 
            :src="iFrameSource" 
            :css-style="{
                position: 'absolute',
                width: 0,
                height: 0,
                border:0
            }" />

        <v-btn v-if="false" small :disabled="!silentIframeEnabled()" depressed @click="toggleIFrame">
            refresh token - {{ Math.round(tokenTimeLeft / 1000) }} s
        </v-btn>
    </div>
</template>

<script>
import IFrame from '../generic/IFrame.vue';
import { deleteToken } from '@/utils/auth';

export default {
    name: 'HiddenIFrameWrapper',
    components: {
        IFrame
    },
    props: {
    },
    data () {
        return {
            iFrameSource: this.$publicPath + '#/hiddenIFrame',
            checkAuthInterval: null,
            showHiddenIFrame: false,
            tokenTimeLeft: this.$getTokenTimeLeft(),
            openIFrameTimeout: null,
            authState: this.$hasAuth()
        }
    },
    mounted() {
        if (this.getAuthState() && this.$route.name !== 'HiddenIFrameResponse') {
            window.addEventListener('storage', this.storageListener);
            this.createHiddenIFrameTimeout();
            this.checkAuthInterval = setInterval(this.checkAuthFn, 5000);
        }
        
    },
    beforeDestroy() {
        clearTimeout(this.openIFrameTimeout);
        clearInterval(this.checkAuthInterval);
        localStorage.removeItem('hideIFrameSignal');
        window.removeEventListener('storage', this.storageListener);
    },
    methods: {
        silentIframeEnabled() {
            return this.$ssoMode && this.$route.name !== 'HiddenIFrameResponse' && localStorage.getItem('hideIFrameSignal') === null;
        },
        getAuthState() {
            this.authState = this.$hasAuth();
            return this.authState;
        },
        checkAuthFn() {
            this.tokenTimeLeft = this.$getTokenTimeLeft();
            if(this.tokenTimeLeft <= 0) {
                this.tokenExpiredFn();
            }
            return this.getAuthState();
        },
        toggleIFrame() {
            if(this.showHiddenIFrame) {
                this.hideIFrame();
            } else {
                this.showIFrame();
            }
        },
        showIFrame() {
            if(this.silentIframeEnabled) {
                this.showHiddenIFrame = true;
                deleteToken();
            }
        },
        hideIFrame() {
            this.showHiddenIFrame = false;
            localStorage.removeItem('hideIFrameSignal');
            this.$nextTick(() => {
                this.createHiddenIFrameTimeout();
            });
        },
        createHiddenIFrameTimeout() {
            if(
                this.silentIframeEnabled() && 
                this.getAuthState() && 
                this.openIFrameTimeout === null &&
                this.$route.name !== 'HiddenIFrameResponse'
                ) {
                this.hideIFrame();
                this.openIFrameTimeout = setTimeout(() => {
                    this.tokenExpiredFn();
                }, this.$getTokenTimeLeft());

            }
        },
        tokenExpiredFn() {
            this.openIFrameTimeout = null;
            this.showIFrame();
        },
        
        storageListener(e) {
            if(e.key === 'hideIFrameSignal' && e.newValue === 'true') {
                this.hideIFrame();
            }
        },
    }
};
</script>