<template>
    <iframe :name="name" :src="src" :ref="'iframe'" :style="cssStyle"></iframe>
</template>

<script>
export default {
    name: 'IFrameElement',
    props: {
        name: {
            type: String,
            default: 'iFrame'
        },
        src: {
            type: String,
            default: null
        },
        cssStyle: {
            type: Object,
            default: null
        }
    }
};
</script>
