
import Vue from 'vue';
import { debounce } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { hasRole, getDetails } from '@/utils/auth';
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
const EventSource = global.EventSource = Vue.mercureEventSource = Vue.prototype.$mercureEventSource =  NativeEventSource || EventSourcePolyfill;

export default {
    data () {
        return {
            eventSource: null,
            reconnectFrequencySeconds: 1,
            onErrorCount: 0,
            onRefreshMercureCount: 0,
            disableReconnect: false
        }
    },
    beforeDestroy() {
        if (this.eventSource instanceof EventSource || this.eventSource instanceof NativeEventSource || this.eventSource instanceof EventSourcePolyfill) {
            this.eventSource.close();
        }
        this.eventSource = null;
        this.clearMercureMessages();
    },
    computed: {
        ...mapGetters({
            hubUrl: 'apps/getHubUrl',
            leaUrl: 'apps/getLeaUrl'
        }),
    },
    methods: {
        ...mapActions({
            refreshMercureCookie: 'apps/refreshMercureCookie',
            addMercureMessage: 'mercure/add',
            clearMercureMessages: 'mercure/clearAllMessages'
        }),
        reconnectMercure: debounce(function() {
            // Double every attempt to avoid overwhelming server
            this.reconnectFrequencySeconds *= 2;
            // Max out at ~1 minute as a compromise between user experience and server load
            if (this.reconnectFrequencySeconds >= 3000) {
                this.reconnectFrequencySeconds = 64;
            }
            this.initMercureSubscriptions();
            if (this.$devMode) {
                console.info('$devMode - MercureMixin: reconnectMercure: ', this.eventSource);
            }
        }, function() { return this.reconnectFrequencySeconds * 1000 }),
        initMercureSubscriptions () {
            this.eventSource && this.eventSource.close();
            if (getDetails() && this.leaUrl && this.hubUrl && this.hubUrl !== '-1') {
                const userId = getDetails().id;
                const url = new URL(this.hubUrl);
                const prefix = this.leaUrl;
                const tenantId = getDetails().tenantId;
                url.searchParams.append('topic', `${prefix}/test/${tenantId}/${userId}`);
                url.searchParams.append('topic', `${prefix}/addUserTask/${tenantId}/${userId}`);
                url.searchParams.append('topic', `${prefix}/updateUserTaskState/${tenantId}/${userId}`);
                url.searchParams.append('topic', `${prefix}/storeAction/${tenantId}`);
                
                if (hasRole('ROLE_EDITOR')) {
                    url.searchParams.append('topic', `${prefix}/processingActivityUpdate/${tenantId}`);
                    url.searchParams.append('topic', `${prefix}/workflowUpdate/${tenantId}`);
                }

                if (hasRole('ROLE_CONSULTANT')) {
                    url.searchParams.append('topic', `${prefix}/addConsultantTask/${tenantId}`);
                    url.searchParams.append('topic', `${prefix}/updateConsultantTaskState/${tenantId}`);
                }

                const lastEventId = localStorage.getItem('lastEventId');
                if (lastEventId !== null){
                    url.searchParams.append('Last-Event-ID', lastEventId);
                }

                this.eventSource = new EventSource(url, {
                    withCredentials: true,
                    lastEventIdQueryParameterName: 'Last-Event-Id'
                });

                this.eventSource.onopen = () => {
                    // Reset reconnect frequency upon successful connection
                    this.reconnectFrequencySeconds = 1;
                    this.onErrorCount = 0;
                    this.onRefreshMercureCount = 0;
                }
                this.eventSource.onmessage = (e) => {
                    localStorage.setItem('lastEventId', e.lastEventId);
                    url.searchParams.set('Last-Event-ID', e.lastEventId);
                    const data = JSON.parse(e.data);
                    if (this.$devMode) {
                        console.info('$devMode - MercureMixin: eventSource onmessage: ', data, e);
                    }
                    this.addMercureMessage({
                        topic: data.topic,
                        data: data
                    });
                }
                
                this.eventSource.onerror = (e) => {
                    if (this.$devMode) {
                        console.error('$devMode - MercureMixin: eventSource onerror: ', e);
                    }
                    this.eventSource.close();
                    
                    this.onErrorCount++;
                    if(this.onErrorCount > 21) {
                        this.disableReconnect = true;
                    }
                    if (!this.disableReconnect) {
                        if (this.onErrorCount % 10 === 0) { // refresh only every 10 attempt
                            this.refreshMercureCookie().then(() => {
                                this.reconnectMercure();
                            });
                        } else {
                            this.reconnectMercure();
                        }
                    } else {
                        console.info('$devMode - MercureMixin: server is not accessible. Requests will be performed again after reloading the page');
                    }
                }
            }
        }
    }
};
