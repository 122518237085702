<template>
  <v-menu
        v-if="currentUser"
        v-model="userMenu"
        :close-on-content-click="false"
        offset-y
        left
        :nudge-right="24"
        :nudge-bottom="12"
        :nudge-width="$vuetify.breakpoint.mdAndUp ? 520 : 200"
        :z-index="200"
        tile
    >
        <template v-slot:activator="{ on, attrs }">
            <div class="d-inline-block ma-2 ml-4">
                <v-badge
                    v-on="on"
                    :value="hasBadge"
                    color="warning"
                    dot
                    bordered
                    dark
                    class="v-badge-diff"
                    :offset-y="11"
                    :offset-x="8"
                >
                    <v-btn
                        color="white"
                        fab
                        small
                        outlined
                        :elevation="2"
                        v-bind="attrs"
                        v-on="on"
                        :height="40"
                        :width="40"
                    >
                        <v-avatar color="white" :size="userMenu ? 32 : 38">
                            <span class="primary--text" v-text="currentUserInitials" />
                        </v-avatar>
                    </v-btn>
                </v-badge>
            </div>
        </template>
        <v-card v-if="$devMode && false" color="orange">
          <v-card-title>
              dev mode
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="mercureTestUserId" :label="'user id (default 25)'" />
            <v-btn @click="mercureTest" color="primary">Notification test</v-btn>
          </v-card-text>
        </v-card>
        <v-card tile>
            <v-list>
                <v-list-item>
                    <v-list-item-avatar color="grey lighten-3">
                        <span class="primary--text" v-text="currentUserInitials" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-text="currentUser.fullName" />
                        <v-list-item-subtitle v-text="currentUser.email" />
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <UserTasks @refetch="fetchData(true)" @close="userMenu = false" />
        </v-card>
    </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getDetails } from '@/utils/auth';
import http from '@/utils/axios';
import UserTasks from '@/components/UserTasks/';

export default {
    name: 'CurrentUserAvatarMenu',
    components: {
        UserTasks
    },
    data () {
        return {
            mercureTestUserId: null,
            userMenu: false,
        }
    },
    computed: {
        ...mapGetters({
            taskItems: 'dashboard/getContactTask'
        }),
        openTasks() {
            return this.taskItems.filter(x => ['open', 'reopened'].includes(x.status));
        },
        hasBadge() {
            return !!this.openTasks.length;
        },
        currentUser() {
            return getDetails();
        },
        currentUserInitials() {
            if (!this.currentUser) {
                return '';
            }
            const firstName = this.currentUser.firstName;
            const lastName = this.currentUser.lastName;
            return this.currentUser && firstName && lastName ? firstName.charAt(0) + lastName.charAt(0) : ''
        }
    },
    methods: {
        ...mapActions({
            fetchContactTasks: 'dashboard/fetchContactTasks'
        }),
        async fetchData(force = false) {
            this.$wait.start('fetch tasks');
            await this.fetchContactTasks({force});
            this.$wait.end('fetch tasks');
        },
        mercureTest () {
            if (this.$devMode) {
                const id = this.mercureTestUserId || 25;
                http.get('/api/test/' + id).then(() => {
                    //console.log('send api/test');
                });
            }
        }
    },
    created () {  
        this.fetchData();
    }
}
</script>